import { css } from 'styled-components';

const queries = [{
    media: 'min-height: 64em',
    name: 'desktop'
}, {
    media: 'max-height: 24em',
    name: 'landscape'
}, {
    media: 'max-width: 85.375em',
    name: 'laptop'
}, {
    media: 'max-width: 48em',
    name: 'tablet'
}, {
    media: 'max-width: 32.87em',
    name: 'phone'
}];

const withMedia = (device, rules) => {
    let output;

    queries.forEach(({ media, name }) => {
        if (name !== device) return false;
        output = css`
            @media (${media}){ 
                ${rules}
        }`;
    });

    return output;

};

export default withMedia;
export { queries };