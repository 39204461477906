const vars = {
    primary: '#E52225',
    primaryHover: 'B31B1D',
    secondary: '#3E3E3E',
    secondaryHover: '#000',
    light: '#FFF',
    success: '#00982A',
    danger: '#980B0D',
    dark: '#1F1F1F'
};

export default vars;