import React from 'react';
import { ApolloProvider } from 'react-apollo';
import Helmet from 'react-helmet';
import StyleSheet from './src/components/StyleSheet';
import client from './src/lib/apollo';

export const wrapRootElement = ({ element }) => (
    <ApolloProvider client={client}>
        {element}
    </ApolloProvider>
);

export const wrapPageElement = ({ element, props }) => {
    return (<>
        <StyleSheet />
        <Helmet>
            <html lang="en" />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={props.location.href} />
            <script async src="https://polyfill.io/v3/polyfill.min.js?features=default%2CSymbol%2CWeakMap%2CWeakSet" />
        </Helmet>
        {element}
    </>);
};