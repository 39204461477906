import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import Cookies from "js-cookie";
import { navigate } from "gatsby";
import fetch from "isomorphic-fetch";

const request = async (operation) =>
  operation.setContext({
    headers: {
      authorization: `Bearer ${Cookies.get("access_token")}`,
    },
  });

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const cache = new InMemoryCache({
  addTypename: false,
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, forward, operation }) => {
      let unauthorized = false;
      if (graphQLErrors)
        graphQLErrors.forEach(({ extensions }) => {
          if (extensions.code === "UNAUTHENTICATED") {
            unauthorized = true;
          }
        });

      if (unauthorized)
        return generateNewAccessToken().flatMap(() => forward(operation));
    }),

    requestLink,
    createUploadLink({ uri: process.env.GATSBY_DATASOURCE, fetch }),
  ]),

  cache,
});

const generateNewAccessToken = () =>
  new Observable((observer) =>
    fetch(`${process.env.GATSBY_DATASOURCE}?query=${formatQuery()}`)
      .then((response) => response.json())
      .then(({ data }) => {
        Cookies.set("access_token", data.refresh.access);
        observer.next(data);
        observer.complete();
      })
      .catch(() => {
        navigate("/login");
      })
  );

const formatQuery = () => {
  let jwt = Cookies.get("refresh_token");
  return `{refresh(jwt:"${jwt}"){access}}`;
};

export default client;
