import { createGlobalStyle, css } from "styled-components";
import withMedia from "./withMedia";
import vars from "../lib/styled";

const stylesheet = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Muli:400,900');

    body,
    html { 
        font-family: "Muli", sans-serif;
        font-size: 1em;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    header,
    footer, 
    main, 
    section, 
    div {
        box-sizing: border-box;
    }

    h1, 
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 800;
        margin: 3rem 0 2rem 0;
    }

    h1 {
        font-size: 2.822em;
        ${withMedia(
          "tablet",
          css`
            font-size: 2.1em;
          `
        )}
    }

    h2 {
        font-size: 1.476em;
    }
    
    h3 {
        font-size: 1.296em;
        line-height: 1.6;
    }

    p {
        font-size: 1em;
        line-height: 1.5;
    }

    a { 
        color: ${vars.primary};
        font-size: 1em;
        font-weight: 400;
        text-decoration: none 
    }

    hr { 
        border-color: #FFF;
        border-width: 1px;
        display: inline-block;
        width: 100px; 
    }
    
    form { display: block }
    
    label { 
        display: block;  
        margin-bottom: 1rem; 
        position: relative;

        svg { 
            height: 1.75rem;
            position: absolute;
            right: 1rem;
            top: calc(50% + 1rem);
            transform: translateY(-50%);
            width: 1.75rem;
        }
        
        input, 
        select, 
        textarea {
            margin-top: 0.5rem;
        }
    }

    input:disabled {
        cursor: not-allowed !important;
    }
    
    input:not([type=checkbox]),
    input:not([type=radio]),
    input:not([type=file]),
    textarea,
    select, 
    .file {
        background-color: #FFF;
        box-sizing: border-box;
        border: 0;
        border-radius: 5px;
        display: block;        
        font-family: inherit;
        font-size: 1em;
        max-width: 100%;
        padding: 0 1rem;
        width: 100%;
    }

    [type=checkbox],
    [type=radio] {
        display: inline !important;
        height: auto;
        width: auto !important;
    }

    .file {
        cursor: pointer;
        height: 3.5rem;
        line-height: 3.5;
        margin-top: 0.5rem;
        overflow: hidden;
    }

    [type=file]{
        position: absolute;
        top: 0;
        visibility: hidden;
    }

    input, 
    select,
    textarea { 
        line-height: 3.5;
        height: 3.5rem;


        ~ span { color: red }
    }   
    
    textarea { 
        line-height: 1.5;
        min-height: 6rem;
        padding-top: 0.5rem;
    }

    fieldset {
        border: 0;
        display: flex;
        margin-bottom: 2rem;
        padding: 2rem 0 0 0;
        justify-content: space-between;
    }

    legend {
        display: block;
        font-size: 1.3em;
        font-weight: bold;
        width: 100%;
    }

    table,
    thead, 
    tfoot, 
    tbody { 
        box-sizing: border-box;
        display: block;
        max-width: 100%;
        position: relative;
        width: 100%;
    }

    thead {
        background-color: #1F1F1F;
        color: #FFF;
    }

    tbody {
        background-color: #FFF;
    }

    table {
        margin: 1.5rem 0 3rem 0;
    }

    caption {
        position: absolute;
        right: 0;
        top: -1.25rem;
    }

    figure {
        margin: 1rem 0;
        padding: 0;
    }

    figcaption {
        font-size: small;
        text-align: right;
    }

    tr {
        display: flex;  
        border-top: 1px solid #E7E8ED;
        justify-content: space-between;
        width: 100%;

        &:first-child {
            border: 0;
        }
    }

    td,
    th {  
        box-sizing: border-box;
        border-left: 1px solid #E7E8ED;
        padding: 1rem;
        text-align: left;
        width: 150px;
        
        &:first-child {
            border: 0;
        }
    }

    tfoot tr { justify-content: flex-end }

    caption { 
        display: block;
        font-size: 0.833em;
        font-style: italic;
    }

    button {
        border-radius: 30px;
        border: 0;
        cursor: pointer;
        font-weight: 600;
    }

    iframe {
        max-width: 100%;
    }

    svg, 
    img {
        max-width: 100%;
    }

    th {
        text-transform: uppercase;
    }

    li p { margin: 0 }

    ::-webkit-scrollbar { width: 1rem }
    ::-webkit-scrollbar-track { background: #fff }
    ::-webkit-scrollbar-thumb { background:  #CCC }
    ::-webkit-inner-spin-button { display: none }
`;

export default stylesheet;
